export const MAIN_URL = '/';

export const CONTACT_EMAIL_ADDRESS = 'piotr.wyczesany@patternapplied.com';

export const HOME_PAGE_TITLE = 'Pattern Applied - Piotr Wyczesany';
export const TRAINING_PAGE_TITLE = {
  CQRS_AXON: 'CQRS and Event Sourcing Applied with Axon Framework',
  DDD_APPLIED: 'Domain-Driven Design Applied',
};
export const TRAINING_PAGE_LINK = {
  CQRS_AXON: '/trainings/cqrs-event-sourcing-axon',
  DDD_APPLIED: '/trainings/domain-driven-design-applied',
};

export const MEDIA = {
  MIN: '768px',
  MED: '992px',
  MAX: '1200px',
}

export const NAVBAR_HEIGHT = {
  MEDIUM: '127px',
  SMALL: '86px',
};

export const SUPPORTED_LOCALES = {
  pl: 'pl',
  en: 'en',
}
